// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreateMatrixFromTemplate_wrapper__G5a8\\+ {
  display: flex;
  flex-direction: column;
  width: 418px;
  margin: auto;
  margin-top: 72px;
}

.CreateMatrixFromTemplate_templatesList__PDtpW {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.CreateMatrixFromTemplate_singleModelWrapper__1hwVi {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
  border: 1px solid #7F56D9;
  padding: 8px 16px;
  border-radius: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/CreateMatrixFromTemplate/CreateMatrixFromTemplate.module.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,YAAA;EACA,gBAAA;AADJ;;AAIA;EACI,qBAAA;EACA,UAAA;EACA,SAAA;AADJ;;AAIA;EACI,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,mBAAA;EACA,SAAA;EACA,yBAAA;EACA,iBAAA;EACA,mBAAA;AADJ","sourcesContent":["@use '../../styles/colors.scss' as colors; \r\n\r\n.wrapper {\r\n    display: flex;\r\n    flex-direction: column;\r\n    width: 418px;\r\n    margin: auto;\r\n    margin-top: 72px;\r\n}\r\n\r\n.templatesList {\r\n    list-style-type: none;\r\n    padding: 0;\r\n    margin: 0;\r\n}\r\n\r\n.singleModelWrapper {\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n    gap: 16px;\r\n    border: 1px solid colors.$PRIMARY600;\r\n    padding: 8px 16px;\r\n    border-radius: 16px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `CreateMatrixFromTemplate_wrapper__G5a8+`,
	"templatesList": `CreateMatrixFromTemplate_templatesList__PDtpW`,
	"singleModelWrapper": `CreateMatrixFromTemplate_singleModelWrapper__1hwVi`
};
export default ___CSS_LOADER_EXPORT___;
