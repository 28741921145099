import React, { FC, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import { CircularProgress } from "@mui/material";

import { useNavigate } from "react-router-dom";

import { AppDispatch, RootState } from "../../store/store";
import { copyModelFromTemplate, fetchTemplates } from "../../store/Templates/templatesSlice";

import { Button } from "../Button/Button";

import { Template } from "../../api/types";

import styles from "./CreateMatrixFromTemplate.module.scss";

export const CreateMatrixFromTemplate: FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const { loading, templates } = useSelector((state: RootState) => state.templates);
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(fetchTemplates());
    }, []);

    const handleAddNewModel = async (template: Template) => {
        await dispatch(copyModelFromTemplate({ template })).then((e) => {
            if (e.type === "templates/copyModelFromTemplate/fulfilled") {
                navigate("/matrixes");
            }
        });
    };

    return (
        <div className={styles.wrapper}>
            <ul className={styles.templatesList}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    templates.map((template, i) => {
                        return (
                            <li key={i} className={styles.singleModelWrapper}>
                                {template.name}
                                <Button variant="secondary" onClick={() => handleAddNewModel(template)}>
                                    Skopiuj ten model
                                </Button>
                            </li>
                        );
                    })
                )}
            </ul>
        </div>
    );
};
